import { Box, Flex, Text } from '@chakra-ui/react';
import { useShoppingCart } from 'context/shoppingCartContext';
import { useSession } from 'next-auth/react';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import AddToCart from '@/components/AddToCart';
import { BadgeNew } from '@/components/Badges/BadgeNew';
import CurrentPrice from '@/components/Card/CurrentPrice';
import { FavouriteButton } from '@/components/FavouriteButton/FavouriteButton';
import { H3 } from '@/components/Heading';
import ProductImage from '@/components/Image/ProductImage';
import { ProductPrice, ProductUnitPrice } from '@/components/Product';
import { ProductCode } from '@/components/Product/ProductCode/ProductCode';
import { ProductLowestPrice } from '@/components/Product/ProductLowestPrice/ProductLowestPrice';
import ProductQuantityHandler from '@/components/ShoppingCart/ProductQuantityHandler';
import ProductQuantityHandlerUnlogged from '@/components/ShoppingCart/ProductQuantityHandlerUnlogged';
import { UnavailableProductButton } from '@/components/UI/Buttons/UnavailableProductButton';
import NavLink from '@/components/UI/Links/NavLink';

import { Product } from '@/models/api';
import { ErrorCode } from '@/models/api/Error';
import {
    DisabledReason,
    ItemsPropsUnlogged,
    ProductCardProps
} from '@/models/props/ProductCardProps';
import { ProductUtil } from '@/utils';

export const ProductCard = (props: ProductCardProps) => {
    const {
        addToCart = true,
        categories,
        code,
        currency,
        discount,
        grossPrice,
        grossPriceBeforeDiscount,
        lowestPriceIn30Days,
        isNew,
        mainImage,
        maximalProductCountInOrder,
        netPrice,
        netPromoPrice,
        netUnitPrice,
        notAvailableMessage,
        points,
        productAvailableForSale,
        slug,
        title,
        unit,
        imageWrapperProps,
        detailsProps,
        imageProps,
        linkToProductPage = true,
        subscribed = false,
        favourite,
        showFavourite = true,
        shortDescription,
        showDescription = false
    } = props;
    const session = useSession();
    const { currentBasket, cartQuantity, fetchingData, basketLocked, cartItemsUnlogged } =
        useShoppingCart();
    const [basketProduct, setBasketProduct] = useState<Product | ItemsPropsUnlogged>();
    const [currQuantity, setCurrQuantity] = useState<number>();
    const [inCart, setInCart] = useState<boolean>(false);
    const productURL = ProductUtil.getProductUrl({
        code,
        slug,
        categories
    });
    const { controls } = currentBasket || {};
    const funcPullQuantity = (q: number) => {
        setCurrQuantity(q);
    };
    const obj = useMemo(
        () => ({
            currentBasket,
            cartQuantity,
            cartItemsUnlogged
        }),
        [currentBasket, cartQuantity, cartItemsUnlogged]
    );
    const categoryName = useMemo(() => categories?.[0]?.subs?.[0].name || '', [categories]);

    useEffect(() => {
        const inBasket =
            session.status === 'authenticated'
                ? obj.currentBasket?.products.find((i) => i.code === code)
                : obj.cartItemsUnlogged?.find((i) => i.code === code);

        if (inBasket) {
            setCurrQuantity(inBasket.quantity);
            setInCart(true);
            setBasketProduct(inBasket);

            return;
        }

        setInCart(false);
    }, [code, currQuantity, obj, session]);

    return (
        <Flex rowGap={6} as="article" w="100%" h="100%" flexDirection="column">
            <Box pos="relative" w="100%">
                <Flex justifyContent="center" alignItems="center" {...imageWrapperProps}>
                    {linkToProductPage && grossPrice ? (
                        <NavLink href={productURL} position="relative" w="100%">
                            <ProductImage
                                mainImage={mainImage}
                                title={title}
                                width={imageProps?.width || 450}
                                height={imageProps?.height || 300}
                            />
                        </NavLink>
                    ) : (
                        <ProductImage
                            mainImage={mainImage}
                            title={title}
                            width={imageProps?.width || 450}
                            height={imageProps?.height || 300}
                        />
                    )}
                </Flex>
                {isNew && (
                    <>
                        <BadgeNew
                            boxProps={{
                                pos: 'absolute',
                                top: 4,
                                left: 4
                            }}
                        />
                    </>
                )}
            </Box>
            <Flex flexDirection="column" flexGrow={1} gap={2} {...detailsProps}>
                <Flex justifyContent="space-between" gap={2}>
                    <Flex flexDirection="column" gap={2} width={[10 / 12]}>
                        {categoryName && (
                            <Text
                                as="span"
                                display="inline-table"
                                paddingY="2px"
                                paddingX={1}
                                bg="grey.100"
                                width="fit-content"
                                lineHeight="1"
                                fontSize="sm"
                                fontWeight="medium"
                            >
                                {categoryName}
                            </Text>
                        )}
                        <H3 size="h4" fontWeight="medium" minH={10} mb={0} lineHeight="1.33">
                            {linkToProductPage && grossPrice ? (
                                <NavLink href={productURL}>{title}</NavLink>
                            ) : (
                                <Text>{title}</Text>
                            )}
                        </H3>
                        <Flex alignItems="center" justifyContent="space-between">
                            <ProductCode productId={code} />
                        </Flex>
                    </Flex>
                    {showFavourite && (
                        <FavouriteButton
                            key={code}
                            checked={favourite}
                            productCode={code}
                            removeFromFavourite={props?.removeFromFavourite}
                            containerStyleProps={{ width: 10, height: 10 }}
                        />
                    )}
                </Flex>
                <Flex flexDirection="column" position="relative" minH={75}>
                    <Flex
                        flexDirection="column"
                        opacity={basketProduct && inCart ? 0 : 1}
                        pointerEvents={basketProduct && inCart ? 'none' : 'auto'}
                    >
                        <ProductPrice
                            grossPrice={grossPrice}
                            grossPriceBeforeDiscount={grossPriceBeforeDiscount}
                            currency={currency}
                            productAvailableForSale={productAvailableForSale}
                            discount={discount}
                            code={code}
                            new={isNew}
                            notAvailableMessage={notAvailableMessage}
                        />
                        <Flex alignItems="center" gap={2} fontSize="xs">
                            {session.status === 'authenticated' && points > 0 && (
                                <Text
                                    fontSize="xs"
                                    fontWeight="semibold"
                                    _after={
                                        productAvailableForSale && grossPrice
                                            ? {
                                                  content: '"|"',
                                                  ml: 2,
                                                  color: 'accent.600'
                                              }
                                            : undefined
                                    }
                                >
                                    {points} <FormattedMessage id="pkt" />
                                </Text>
                            )}
                            <ProductUnitPrice
                                grossPrice={grossPrice}
                                netPrice={netPrice}
                                netPromoPrice={netPromoPrice}
                                netUnitPrice={netUnitPrice}
                                unit={unit}
                                currency={currency}
                                productAvailableForSale={productAvailableForSale}
                            />
                        </Flex>
                        <ProductLowestPrice
                            discount={discount}
                            isNew={isNew}
                            lowestPriceIn30Days={lowestPriceIn30Days}
                            currency={currency}
                            textStyleProps={{ fontSize: 'xs' }}
                        />
                    </Flex>
                    {!(basketProduct && currency && inCart) &&
                        shortDescription &&
                        showDescription && (
                            <Text
                                mt={3}
                                mb={2}
                                color="grey.main"
                                noOfLines={3}
                                dangerouslySetInnerHTML={{
                                    __html: shortDescription
                                }}
                            ></Text>
                        )}
                    {basketProduct && currency && inCart && (
                        <Box
                            pointerEvents={fetchingData ? 'none' : 'auto'}
                            opacity={fetchingData ? 0.5 : 1}
                        >
                            <CurrentPrice
                                product={basketProduct as Product}
                                currQuantity={currQuantity ?? 0}
                                currency={currency || ''}
                            />
                        </Box>
                    )}
                </Flex>
                {productAvailableForSale !== undefined && addToCart && !basketLocked && (
                    <Box mt="auto">
                        {productAvailableForSale && grossPrice ? (
                            currQuantity !== undefined && inCart ? (
                                <>
                                    {session.status === 'authenticated' ? (
                                        controls?.editable ? (
                                            <Box
                                                pt={5}
                                                width="100%"
                                                pointerEvents={fetchingData ? 'none' : 'auto'}
                                                opacity={fetchingData ? 0.5 : 1}
                                            >
                                                <ProductQuantityHandler
                                                    setQuantity={funcPullQuantity}
                                                    code={code}
                                                    quantity={currQuantity}
                                                    maxOrder={maximalProductCountInOrder}
                                                    size="regular"
                                                    fetchingData={fetchingData}
                                                />
                                            </Box>
                                        ) : (
                                            controls?.disabledReason ===
                                                DisabledReason.PendingTransferPayment && (
                                                <Box
                                                    display="grid"
                                                    alignItems="center"
                                                    textAlign="center"
                                                    w="100%"
                                                    h="52px"
                                                >
                                                    <Text color="danger.500">
                                                        <FormattedMessage id={ErrorCode.disabled} />
                                                    </Text>
                                                </Box>
                                            )
                                        )
                                    ) : (
                                        <Box
                                            pt={5}
                                            width="100%"
                                            pointerEvents={fetchingData ? 'none' : 'auto'}
                                            opacity={fetchingData ? 0.5 : 1}
                                        >
                                            <ProductQuantityHandlerUnlogged
                                                setQuantity={funcPullQuantity}
                                                code={code}
                                                grossPrice={grossPrice}
                                                quantity={currQuantity}
                                                maxOrder={30}
                                                size="regular"
                                                fetchingData={fetchingData}
                                            />
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <Flex
                                    width="100%"
                                    pointerEvents={fetchingData ? 'none' : 'auto'}
                                    opacity={fetchingData ? 0.5 : 1}
                                >
                                    <AddToCart
                                        code={code}
                                        quantity={1}
                                        grossPrice={grossPrice}
                                        setQuantity={funcPullQuantity}
                                        maxOrder={maximalProductCountInOrder}
                                        variant="simple"
                                    />
                                </Flex>
                            )
                        ) : (
                            session.data && (
                                <UnavailableProductButton code={code} subscribed={subscribed} />
                            )
                        )}
                    </Box>
                )}
                {basketLocked && (
                    <Box mt="auto" pt={4}>
                        <Box
                            borderWidth={1}
                            borderColor="grey.400"
                            p={4}
                            pointerEvents="none"
                            textColor="grey.400"
                            fontWeight="medium"
                            textAlign="center"
                            textTransform="uppercase"
                            w="100%"
                        >
                            <FormattedMessage id="basked.locked" />
                        </Box>
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
