import { Flex, Text } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormattedPrice } from '@/components/FormattedPrice/FormattedPrice';

import { Product } from '@/models/api';
import { ItemsPropsUnlogged } from '@/models/props/ProductCardProps';
import { AppService } from '@/services';

type CurrentPriceProps = {
    product: Product | ItemsPropsUnlogged;
    currQuantity: number;
    currency?: string;
};

function CurrentPrice({ product, currQuantity }: CurrentPriceProps) {
    const currency = AppService.getInstanceCurrency();
    const session = useSession();
    const [price, setPrice] = useState<string>();

    useEffect(() => {
        if (session.status === 'authenticated') return;

        setPrice((Math.round((product as ItemsPropsUnlogged).grossPrice * 100) / 100).toFixed(2));
    }, [product, session]);

    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            textAlign="center"
            pos="absolute"
            w="100%"
            h="100%"
            bgColor="white"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            zIndex={currQuantity > 0 ? 10 : -1}
            color="brand.main"
            transition="opacity 0.2s ease-in-out"
            opacity={currQuantity > 0 ? 1 : 0}
            pointerEvents={currQuantity > 0 ? 'auto' : 'none'}
            willChange="opacity"
            px={4}
        >
            <Text fontSize="base" fontWeight="semibold">
                <FormattedPrice
                    value={
                        session.status === 'authenticated' ? (product as Product).priceGross : price
                    }
                    currency={currency}
                />
            </Text>
            <Text fontSize="xs" lineHeight="short">
                <FormattedMessage id="product.card.basket-updated" />
            </Text>
        </Flex>
    );
}

export default CurrentPrice;
