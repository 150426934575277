import {
    ButtonProps,
    Flex,
    FlexProps,
    Text,
    Tooltip,
    useToken,
    VisuallyHidden
} from '@chakra-ui/react';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useFavouriteButton } from '@/components/FavouriteButton/useFavouriteButton';
import { HeartIcon } from '@/components/Icons';
import { Button } from '@/components/UI/Buttons/Button';

import { CookieKeys } from '@/constants/cookie-keys';
import RoutePath from '@/constants/route-path';
import { ProductIdProps } from '@/models/props/ShoppingCartContextProps';

import { useApp } from '../../../context/appContext';

interface FavouriteButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    checked?: boolean;
    productCode: ProductIdProps;
    labelChecked?: string;
    labelUnchecked?: string;
    showLabel?: boolean;
    buttonStyleProps?: ButtonProps;
    containerStyleProps?: FlexProps;
    removeFromFavourite?: (code: ProductIdProps) => void;
}

export const FavouriteButton = (props: FavouriteButtonProps) => {
    const {
        checked,
        productCode,
        labelChecked,
        labelUnchecked,
        buttonStyleProps,
        containerStyleProps,
        removeFromFavourite,
        showLabel = false,
        ...rest
    } = props;
    const { addProductToFavourites, removeProductFromFavourites, isChecked, inProgress } =
        useFavouriteButton({
            checked
        });
    const { loginAllowed, showDisabledLoginPopup } = useApp();
    const intl = useIntl();
    const { asPath, push } = useRouter();
    const session = useSession();

    const [tooltipText, setTooltipText] = useState('');
    const [selectedColor, notSelectedColor, brandColor500] = useToken('colors', [
        'brand.main',
        'black',
        'brand.main'
    ]);

    const buttonClickHandler = useCallback(async () => {
        if (!loginAllowed) {
            showDisabledLoginPopup();
            return;
        }
        if (!session.data) {
            setCookie(CookieKeys.addToFavouritesProductCode, productCode);
            await push(RoutePath.SignIn.concat('?redirect=', asPath));
            return;
        }
        if (inProgress) {
            return;
        }
        if (!isChecked) {
            await addProductToFavourites(productCode);
            return;
        }
        await removeProductFromFavourites(productCode);
        removeFromFavourite?.(productCode);
    }, [
        loginAllowed,
        session.data,
        inProgress,
        isChecked,
        removeProductFromFavourites,
        productCode,
        removeFromFavourite,
        push,
        asPath,
        addProductToFavourites,
        showDisabledLoginPopup
    ]);

    useEffect(() => {
        const translationKey = isChecked
            ? 'favorites.remove-from-favourites.tooltip-text'
            : 'favorites.add-to-favourites.tooltip-text';
        setTooltipText(intl.formatMessage({ id: translationKey }));
    }, [isChecked, intl]);

    return (
        <Flex {...containerStyleProps}>
            <Button
                isLoading={inProgress}
                position="relative"
                variant="outline"
                display="flex"
                fontSize={18}
                _hover={{
                    color: selectedColor,
                    bgColor: 'accent.200'
                }}
                _focus={{
                    color: selectedColor
                }}
                _focusVisible={{
                    boxShadow: `0 0 0 3px ${brandColor500} inset`
                }}
                zIndex={5}
                onClick={buttonClickHandler}
                innerContainerProps={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                borderColor={buttonStyleProps?.borderColor ?? 'accent.border'}
                width={buttonStyleProps?.width ?? '2.5rem'}
                height={buttonStyleProps?.width ?? '2.5rem'}
                {...buttonStyleProps}
                color={isChecked ? selectedColor : buttonStyleProps?.color || notSelectedColor}
                {...rest}
            >
                <VisuallyHidden>
                    <FormattedMessage
                        id={
                            isChecked
                                ? 'favorites.remove-from-favourites.tooltip-text'
                                : 'favorites.add-to-favourites.tooltip-text'
                        }
                    ></FormattedMessage>
                </VisuallyHidden>
                <Tooltip hasArrow label={!inProgress && tooltipText}>
                    <HeartIcon
                        fontSize={25}
                        color={isChecked ? selectedColor : 'black'}
                        fill={isChecked ? selectedColor : 'transparent'}
                        stroke={isChecked ? selectedColor : 'black'}
                    />
                </Tooltip>
                {showLabel && <Text ml={1}>{isChecked ? labelChecked : labelUnchecked}</Text>}
            </Button>
        </Flex>
    );
};
